<template>
    <div class="d-flex flex-column">
        <h2 class="headline text-center mb-2">Winkelwagen</h2>
        <v-divider></v-divider>
        <v-alert
            class="mt-5 text-center"
            color="amber lighten-5"
            v-if="lines.length <= 0"
        >
            Je bestelling is nog leeg
        </v-alert>
        <v-simple-table class="mt-2" v-if="lines.length > 0">
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left" width="140">Aantal</th>
                    <th class="text-left">Omschrijving</th>
                    <th class="text-right" width="130">Stukprijs</th>
                    <th class="text-right" width="130">Regelprijs</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="line in linesReversed" :key="line.id">
                    <td>
                        <div class="d-flex align-center quantity-input">
                            <v-btn
                                icon
                                @click="updateQuantity(line, (line.quantity-1))"
                                :disabled="line.quantity === 1"
                                x-small
                                class="mr-2"
                            >
                                <v-icon>fal fa-minus</v-icon>
                            </v-btn>
                            <v-text-field
                                :value="line.quantity"
                                hide-details
                                filled
                                dense
                                single-line
                                class="text-center"
                                @input="updateQuantityOnInput(line, $event)"
                                @focus="toggleBarcodeInput(false)"
                                @blur="toggleBarcodeInput(true)"
                            >
                            </v-text-field>
                            <v-btn
                                icon
                                @click="updateQuantity(line, (line.quantity+1))"
                                x-small
                                class="ml-2"
                            >
                                <v-icon>fal fa-plus</v-icon>
                            </v-btn>
                        </div>
                    </td>
                    <td class="py-2">
                        <v-btn
                            @click="removeLine(line)"
                            color="error"
                            icon
                            class="float-right"
                        >
                            <v-icon small>fa fa-trash</v-icon>
                        </v-btn>
                        {{ line.item.name }}
                        <br/>
                        <v-chip label color="red" x-small v-if="showCapacity && line.item.items_remaining <= 0">Uitverkocht</v-chip>
                        <v-chip label color="orange" x-small v-else-if="showCapacity && line.item.items_remaining <= 10">Nog {{ line.item.items_remaining }} beschikbaar</v-chip>
                        <br v-if="line.eanCode"><code v-if="line.eanCode">{{line.eanCode}}</code>
                    </td>
                    <td class="text-right text-no-wrap">
                        <del
                            class="mb-3 mr-1 text--disabled"
                            v-if="(line.item.originalPrice != line.item.salesPrice) && line.item.originalPrice > 0"
                        >
                            <small>{{ line.item.originalPrice|format_dinero }}</small>
                        </del>
                        {{ line.item.salesPrice|format_dinero }}
                    </td>
                    <td class="text-right text-no-wrap">
                        {{ getLinePrice(line)|format_dinero }}
                    </td>
                </tr>
                </tbody>
                <tfoot>
                <tr class="totals" v-if="false">
                    <td colspan="3" class="text-right">
                        Subtotaal:
                    </td>
                    <td class="text-right text-no-wrap">{{ lineTotal|format_dinero }}</td>
                </tr>
                <tr class="spacer" v-if="false">
                    <td colspan="3"></td>
                </tr>
                <tr class="totals grand-total">
                    <td class="text-center">{{ quantityTotal }}</td>
                    <td colspan="2" class="text-right">
                        Totaal:
                    </td>
                    <td class="text-right text-no-wrap">
                        {{ cartTotal|format_dinero }}
                    </td>
                </tr>
                </tfoot>
            </template>
        </v-simple-table>
        <v-divider class="my-5" v-if="customerInput !== 'none'"></v-divider>
        <div class="d-flex" v-if="customerInput === 'barcode'">
            <v-text-field
                outlined
                v-model="customerBarcode"
                label="Scan barcode van klant"
                hide-details="auto"
                :required="true"
                @focus="toggleBarcodeInput(false)"
                @blur="toggleBarcodeInput(true)"
                @keyup.enter="onCustomerInputEnter($event)"
            ></v-text-field>
        </div>
        <v-divider class="my-5"></v-divider>

        <div class="d-flex">
            <div class="flex-grow-1 w-100 pr-3">
                <table class="w-100">
                    <tr><td>Aantal producten:</td><td class="text-right"><strong>{{quantityTotal}}</strong></td></tr>
                    <tr><td>Totaalbedrag:</td><td class="text-right"><strong>{{ cartTotal|format_dinero }}</strong></td></tr>
                </table>

                <CartGroupSummary :shop-id="shopId"></CartGroupSummary>
            </div>
            <div class="payment-methods section flex-grow-0" v-if="paymentConfiguration">
                <p class="section-title">Betaalmethode:</p>
                <v-radio-group row v-model="paymentMethod" :disabled="isSubmitting">
                    <div class="payment-method d-flex align-center"
                         v-for="(label, key) in paymentConfiguration.methods" :key="key">
                        <v-radio class="mr-3" :value="key"
                                 :label="label"></v-radio>
                    </div>
                    <!--                <div class="payment-method d-flex align-center flex-fill"-->
                    <!--                     v-if="paymentConfiguration.addOther">-->
                    <!--                    <v-radio class="mr-3" value="other"-->
                    <!--                             label="Anders"></v-radio>-->
                    <!--                    <v-text-field-->
                    <!--                        class="ml-auto"-->
                    <!--                        hide-details-->
                    <!--                        solo-->
                    <!--                        dense-->
                    <!--                        :style="{ 'visibility': (paymentMethod === 'other') ? 'visible' : 'hidden' }"-->
                    <!--                        v-model="paymentMethodDescription"-->
                    <!--                    ></v-text-field>-->
                    <!--                </div>-->
                </v-radio-group>
            </div>
        </div>
        <v-divider class="my-5" v-if="paymentConfiguration"></v-divider>

        <div class="d-flex align-center pt-2">
            <v-btn outlined @click="clearCart" :disabled="isSubmitting" small color="accent">
                Winkelwagen leegmaken
            </v-btn>
            <v-btn @click="submitOrder(false)" :disabled="isSubmitting || !canCheckout" color="primary" class="ml-auto"
                   x-large>
                <v-icon class="mr-4">fal fa-money-bill</v-icon>
                Betaald
            </v-btn>
        </div>
    </div>
</template>

<script>
import { isNegative, multiply} from "dinero.js";
import CartGroupSummary from "./CartGroupSummary";

const debounce = require('lodash.debounce');

export default {
    name: "CartOverview",
    components: {CartGroupSummary},
    props: {
        shopId: {
            type: Number,
            required: true
        }
    },
    data: () => ({
        isSubmitting: false,
        paymentConfiguration: {
            methods: {
                pos_pin: 'Pin',
                pos_cash: 'Contant'
            },
            addOther: false
        }
    }),
    computed: {
        showCapacity() {
            return this.shopConfiguration.hasCapacityChecks
        },
        lines() {
            return this.$store.state.pointofsale.cartLines;
        },
        linesReversed() {
            return this.lines.slice().reverse();
        },
        lineTotal() {
            return this.$store.getters.getCartLineTotal;
        },
        cartTotal() {
            return this.$store.getters.getCartTotal;
        },
        quantityTotal() {
            return this.$store.state.pointofsale.cartLines.reduce(
                (acc, cartLine) => {
                    return acc + cartLine.quantity;
                },
                0
            );
        },
        canCheckout() {
            // Check if at least one item in the order
            if (this.lines.length === 0) {
                return false;
            }
            // Check if the amount is not negative
            if (isNegative(this.cartTotal)) {
                return false;
            }
            // Check if barcode has been entered
            if (this.customerInput === 'barcode' && this.customerBarcode.trim() === '') {
                return false;
            }
            // Check if payment method has been selected
            if (this.paymentConfiguration && !this.paymentMethod) {
                return false;
            }
            return true;
        },
        shopConfiguration() {
            return this.$store.getters.getShopConfigurationById(this.shopId)
        },
        /**
         * Returns whether the customer should be stored with the order.
         * @returns null|String
         */
        customerInput() {
            return this.shopConfiguration?.customerInput;
        },
        customerBarcode: {
            get() {
                let info = this.$store.state.pointofsale.cartCustomerInfo;
                return info ? (info.barcode || '') : '';
            },
            set(val) {
                this.$store.commit('SET_CART_CUSTOMER_INFO', {barcode: val})
            }
        },
        paymentMethod: {
            get() {
                return this.$store.state.pointofsale.cartPaymentMethod
            },
            set(val) {
                this.$store.commit('SET_CART_PAYMENT_METHOD', val);
            }
        }
    },
    methods: {
        getLinePrice(cartLine) {
            return multiply(cartLine.item.salesPrice, cartLine.quantity);
        },
        updateQuantity(line, quantity) {
            this.$store.dispatch('updateCartLine', {id: line.id, quantity: Math.max(1, quantity)});
        },
        updateQuantityOnInput: debounce(function (line, value) {
            if (!isNaN(parseInt(value))) {
                this.updateQuantity(line, parseInt(value))
            }
        }, 200),
        removeLine(line) {
            this.$store.dispatch('removeFromCart', {id: line.id})
        },
        clearCart() {
            this.$store.dispatch('resetCart', {shopId: this.shopId});
        },
        submitOrder() {
            this.isSubmitting = true;
            this.$store.dispatch('submitPointOfSaleOrder', {shopId: this.shopId}).then(result => {
                if (result.success) {
                    const order = result.order;

                    this.$emit('orderCreated', order);

                    this.clearCart()

                } else {
                    this.$store.dispatch('showSnackbar', {
                        text: 'Bestelling kon niet worden aangemaakt',
                        color: 'error'
                    });
                }
            }).catch((e) => {
                console.error(e);
                this.$store.dispatch('showSnackbar', {text: 'Bestelling kon niet worden aangemaakt', color: 'error'});
            }).finally(() => {
                this.isSubmitting = false;
            })
        },
        /**
         * Toggle the barcode input on the parent view
         * Used to temporary read barcodes while a field is selected that has it's own barcode input
         * @param value Boolean
         */
        toggleBarcodeInput(value) {
            this.$emit('toggleBarcodeInput', value)
        },
        onCustomerInputEnter($event) {
            // After an enter press in the customer input, we blur the field
            // This will make sure additional barcode inputs will be send to the main barcode input
            $event.target.blur()
        }
    }
}
</script>

<style scoped>

</style>
