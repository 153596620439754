<template>
    <v-container fluid class="view-pointofsale-create-order">
        <PrinterWarningBanner v-if="hasReceiptLabel"
                              class="mb-3"></PrinterWarningBanner><!-- Andere checks voor printer worden in de banner zelf gedaan -->
        <v-row class="justify-center wrap px-5">
            <v-col md="6">
                <ScanInput
                    v-if="hasScanInput"
                    defaultText="Scan een barcode"
                    ref="scanInput"
                    compact
                    class="mb-3"
                    :enable-key-input="enableScanKeyInput"
                    @input="onInput"
                ></ScanInput>
                <ItemList
                    ref="itemList"
                    :shop-id="shopId"
                    @selectedItem="onItemSelected"
                ></ItemList>
            </v-col>
            <v-col md="6">
                <OrderOverview
                    v-if="order"
                    :shop-id="shopId"
                    :order="order"
                    @closeOrder="onOrderClosed()"
                    @toggleBarcodeInput="enableScanKeyInput = $event"
                ></OrderOverview>
                <CartOverview
                    v-else
                    :shop-id="shopId"
                    @orderCreated="onOrderCreated"
                    @toggleBarcodeInput="enableScanKeyInput = $event"
                ></CartOverview>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ItemList from "@/components/pointofsale/ItemList";
import ScanInput from "@/components/ScanInput";
import CartOverview from "@/components/pointofsale/CartOverview";
import OrderOverview from "../../components/pointofsale/OrderOverview";
import PrinterWarningBanner from "@/components/layout/PrinterWarningBanner";

export default {
    name: "CreateOrder",
    components: {PrinterWarningBanner, OrderOverview, CartOverview, ItemList, ScanInput},
    mounted() {
        this.loadAppInfo();

        // Disabled auto , because this causes
        // this.autoUpdateTimer = window.setInterval(() => {
        //     this.refresh()
        // }, this.$store.state.app.autoUpdateInterval * 1000)
    },

    // beforeDestroy() {
    //     window.clearTimeout(this.autoUpdateTimer)
    // },

    data: () => ({
        order: null,
        enableScanKeyInput: true,
        isProcessing: false
    }),

    computed: {
        shopId() {
            return parseInt(this.$route.params.shop_id);
        },
        shopConfiguration() {
            return this.$store.getters.getShopConfigurationById(this.shopId)
        },
        hasReceiptLabel() {
            return this.shopConfiguration && this.shopConfiguration.receiptLabelId
        },
        hasScanInput() {
            return this.shopConfiguration && this.shopConfiguration.itemViewConfig && this.shopConfiguration.itemViewConfig.enableScanInput
        }
    },

    watch: {
        $route() {
            this.loadAppInfo()
        }
    },

    methods: {
        loadAppInfo() {
            this.$store.dispatch('getAppInfo', {id: this.shopId, type: 'pointofsale'}).then(app => {
                this.app = app;
                this.$store.commit('SET_HEADER', {
                    title: app.title,
                    headerMode: this.$route.meta.headerMode,
                    isPrinterSelectionVisible: true,
                    isRefreshButtonVisible: true
                })
            }).then(() => {
                this.$store.dispatch('setActiveCartShop', {shopId: this.shopId});
            })
        },
        refresh() {
            this.$store.dispatch('updatePointOfSale', {
                shopConfiguration: this.shopConfiguration,
                incremental: true
            });
        },
        refreshFromHeader() {
            this.$store.dispatch('updatePointOfSale', {
                shopConfiguration: this.shopConfiguration,
                incremental: false
            });
        },
        onItemSelected(item) {
            this.$store.dispatch('addToCart', {shopId: this.shopId, item});
            this.order = null;
        },
        onOrderCreated(order) {
            this.$refs.itemList.resetFilters()
            this.order = order;
        },
        onOrderClosed() {
            this.order = null;
        },
        onInput(barcode) {
            this.isProcessing = true;
            this.$store.dispatch('searchPointOfSaleBarcode', {shopId: this.shopId, barcode})
                .then(result => {
                    if (result.item) {
                        this.$refs.scanInput.showSuccessStatus(result.message, result.information);
                        this.$store.dispatch('addToCart', {shopId: this.shopId, item: result.item, eanCode: result.barcode });
                    } else {
                        this.$refs.scanInput.showErrorStatus(result.message, result.information);
                    }
                })
        },
    }
}
</script>

<style lang="scss">

tr.totals td {
    font-size: 1.2rem;
    font-weight: bold;
}

tr.grand-total td {
    font-style: italic;
    font-size: 1.5rem;
}

.quantity-input {
    .v-input {
        max-width: 50px;
    }

    .v-input__slot {
        padding: 0;
    }

    input {
        text-align: center;
    }
}
</style>
