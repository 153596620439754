<template>
    <div class="d-flex flex-column">
        <v-btn-toggle
            v-if="tabs"
            background-color="primary"
            v-model="selectedTab"
            class="flex-fill mb-3"
        >
            <v-btn
                v-for="(tab, key) in tabs"
                :key="key"
                class="flex-fill"
            >
                {{tab.label}}
            </v-btn>

        </v-btn-toggle>
        <v-list>
            <v-list-item v-for="item in filteredItems"
                         :key="item.id"
                         two-line
                         @click="onItemClick(item)"
                         :style="getItemStyle(item)"
                         :dark="getItemDark(item)"
            >
                <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                        {{ item.description }} 
                        <v-chip label color="red" x-small v-if="showCapacity && item.items_remaining <= 0">Uitverkocht</v-chip>
                        <v-chip label color="orange" x-small v-else-if="showCapacity && item.items_remaining <= 10">Nog {{ item.items_remaining }} beschikbaar</v-chip>
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-list-item-action-text>{{ item.salesPrice|format_dinero }}</v-list-item-action-text>
                </v-list-item-action>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
export default {
    name: "ItemList",
    props: {
        shopId: {
            type: Number,
            required: true
        }
    },
    data: () => ({
        selectedTab: null
    }),
    computed: {
        items() {
            return this.$store.getters.getItemsForShop(this.shopId);
        },
        filteredItems() {
            let items = this.items;

            if (this.selectedTab || this.selectedTab === 0) {
                const tabConfig = (this.tabs[this.selectedTab])
                items = items.filter(i => tabConfig.items.includes(i.id));
            }

            if (this.useConfigOrder) {
                return this.sortByConfigOrder(items);
            } else {
                return items.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
            }
        },
        shopConfiguration() {
            return this.$store.getters.getShopConfigurationById(this.shopId)
        },
        showCapacity() {
            return this.shopConfiguration.hasCapacityChecks
        },
        itemViewConfig() {
            return this.shopConfiguration ? this.shopConfiguration.itemViewConfig : null;
        },
        useConfigOrder() {
            return this.itemViewConfig ? !!this.itemViewConfig.useConfigOrder : false;
        },
        tabs() {
            return this.itemViewConfig ? this.itemViewConfig.tabs : null;
        }
    },
    methods: {
        onItemClick(item) {
            this.$emit('selectedItem', item);
        },
        resetFilters() {
            this.selectedTab = null;
        },
        getItemBackgroundColor(item) {
            if (this.itemViewConfig && this.itemViewConfig.itemColors && this.itemViewConfig.itemColors[item.id]) {
                return this.itemViewConfig.itemColors[item.id];
            } else {
                return null
            }
        },
        getItemStyle(item) {
            const backgroundColor = this.getItemBackgroundColor(item);
            if (backgroundColor) {
                return { backgroundColor }
            } else {
                return {};
            }
        },
        getItemDark(item) {
            const backgroundColor = this.getItemBackgroundColor(item);
            if (backgroundColor) {
                // Beetje een trucje: normaal geeft de functie een light/dark color terug
                // Maar door true/false terug te geven, sturen we de `dark` property
                return pickTextColorBasedOnBgColorAdvanced(backgroundColor, true, false);
            } else {
                return false;
            }
        },
        sortByConfigOrder(items) {
            let itemOrders = {};
            if (this.tabs) {
                let index = 1;
                this.tabs.forEach(tab => {
                    tab.items.forEach((itemId) => {
                        itemOrders[itemId] = index++;
                    })
                })
            }

            return items.sort((a, b) => {
                let oa = itemOrders[a.id] || (90000 + a.displayOrder);
                let ob = itemOrders[b.id] || (90000 + b.displayOrder);
                return (oa > ob) ? 1 : -1;
            })
        }
    }
}

// function pickTextColorBasedOnBgColorSimple(bgColor, lightColor, darkColor) {
//     var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
//     var r = parseInt(color.substring(0, 2), 16); // hexToR
//     var g = parseInt(color.substring(2, 4), 16); // hexToG
//     var b = parseInt(color.substring(4, 6), 16); // hexToB
//     return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
//         darkColor : lightColor;
// }

function pickTextColorBasedOnBgColorAdvanced(bgColor, lightColor, darkColor) {
    var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    var uicolors = [r / 255, g / 255, b / 255];
    var c = uicolors.map((col) => {
        if (col <= 0.03928) {
            return col / 12.92;
        }
        return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
    return (L > 0.179) ? darkColor : lightColor;
}
</script>

<style scoped>

</style>
