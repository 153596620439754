<template>
    <div>
        <h2 class="headline text-center mb-2">Bestelling #{{ order.orderNo }}</h2>
        <v-divider></v-divider>

        <v-alert
            class="mt-5 text-center"
            color="success lighten-5"
        >
            Bestelling aangemaakt
        </v-alert>

        <p class="my-3">
            Bedrag: <strong>{{ order.orderTotal|format_price }}</strong><br/>
            Betaalmethode: <strong>{{ paymentMethod }}</strong>
        </p>

        <v-divider></v-divider>
        <div class="d-flex align-center pt-2">
            <v-btn @click="printOrderReceipt" color="primary" v-if="hasReceiptLabel"
                :disabled="!hasPrinter">
                <v-icon class="mr-4">fal fa-print</v-icon>
                Print bon opnieuw
            </v-btn>
            <v-dialog
                v-model="cancelDialog"
                width="500"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="error"
                        dark
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon class="mr-4">fal fa-trash</v-icon>
                        Annuleer order
                    </v-btn>
                </template>

                <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                        Bestelling annuleren?
                    </v-card-title>

                    <v-card-text>
                        <p class="mt-3 mb-0">Weet je zeker dat je deze bestelling wilt annuleren?</p>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            text
                            @click="cancelDialog = false"
                        >
                            Sluiten
                        </v-btn>
                        <v-btn
                            color="error"
                            text
                            dark
                            @click="cancelOrder"
                        >
                            Annuleren
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-btn @click="closeOrder" color="success" class="ml-auto"
                   x-large>
                <v-icon class="mr-4">fal fa-times</v-icon>
                Sluiten
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    name: "OrderOverview",
    props: {
        shopId: {
            type: Number,
            required: true
        },
        order: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        cancelDialog: false
    }),
    computed: {
        paymentMethod() {
            switch (this.order.paymentMethodType) {
                case 'pos_cash':
                    return 'Contant';
                case 'pos_pin':
                    return 'PIN';
                default:
                    return this.order.paymentMethodType
            }
        },
        shopConfiguration() {
            return this.$store.getters.getShopConfigurationById(this.shopId)
        },
        hasReceiptLabel() {
            return this.shopConfiguration.receiptLabelId
        },
        hasPrinter() {
            return this.$store.getters.defaultPrinter;
        }
    },
    methods: {
        closeOrder() {
            this.$emit('closeOrder');
        },
        cancelOrder() {
            this.$store.dispatch('cancelOrder', {
                shopId: this.shopId,
                order: this.order
            }).then(() => {
                this.closeOrder()
            })

        },
        printOrderReceipt() {
            this.$store.dispatch('printPointOfSaleOrderReceipt', {
                shopId: this.shopId,
                order: this.order
            })
        }
    }
}
</script>

<style scoped>

</style>
