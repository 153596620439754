<template>
    <div v-if="summaryGroups" class="mt-2">
        <table class="w-100">
            <tr v-for="(group, index) in summaryGroupsWithQuantities" :key="index">
                <td>{{group.name}}:</td>
                <td class="text-right" :class="{'red--text': group.maximum && group.cartQuantity > group.maximum}">
                    {{group.cartQuantity}}
                    <span v-if="group.maximum">/{{group.maximum}}</span>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>

export default {
    name: "CartGroupSummary",
    props: {
        shopId: {
            type: Number,
            required: true
        }
    },
    computed: {
        shopConfiguration() {
            return this.$store.getters.getShopConfigurationById(this.shopId)
        },
        summaryGroups() {
            return this.shopConfiguration?.itemViewConfig?.summaryGroups;
        },
        summaryGroupsWithQuantities() {
            const summaryGroups = this.summaryGroups;
            if (!summaryGroups) {
                return summaryGroups
            }

            return summaryGroups.map(summaryGroup => {
                let cartQuantity = this.getCartQuantityForSummaryGroup(summaryGroup);
                return {...summaryGroup, cartQuantity }
            }).filter(summaryGroup => {
                return summaryGroup.cartQuantity >= 1;
            })
        }
    },
    methods: {
        getCartQuantityForSummaryGroup(summaryGroup) {
            return this.$store.state.pointofsale.cartLines.reduce(
                (acc, cartLine) => {
                    let quantity = 0;
                    if (summaryGroup.itemIds.includes(cartLine.item.id)) {
                        quantity = cartLine.quantity
                    }
                    return acc + quantity;
                },
                0
            );
        }
    }
}
</script>

<style scoped>

</style>
